<mat-toolbar color="primary" class="sticky" *ngIf="progressPercentage$ | async">
  <mat-toolbar-row class="progress-row" fxLayout="row" fxLayoutAlign="space-between center" (click)="redirectToProfile()">
    <div class="thumbnail">
      <img *ngIf="iconNameIndex" src="assets/playbison/levels/icon-playbison-level-{{iconNameIndex}}.png" alt="icon-gamification" />
      <div #pixiAnimation class="animation"></div>
      <div class="label-level-name"  i18n>
        <!-- green -->
        {{playerLoyalityName}}
      </div>
    </div>
    <div style="min-width: 55%;" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutAlign="center">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="info-text">LEVEL</p>
          <p class="info-level">{{(gamificationIndex$ | async)}}</p>
        </div>
        <!-- <div  fxLayout="row" fxLayoutAlign="end start">
          <p class="info-text">Lvl</p>
          <p class="info-level">{{(gamificationIndex$ | async) + 1}}</p>
        </div> -->
      </div>
      <div class="progress-bar-container">
        <ng-container *ngIf="(progressPercentage$ | async) as progress">
          <div class="progress-bar-empty" [style.width.%]="100">
            <div class="progress-bar" [style.width.%]="progress"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="(progressPercentage$ | async) as progress">
      <p class="progress-text">{{progress}}%</p>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>

