import { AfterViewInit, Component, Inject, Injector, LOCALE_ID, NgModuleRef, PLATFORM_ID, Type, ViewChild, ViewContainerRef, createNgModuleRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { GameCategory } from 'src/app/apollo/models/base-models';
import { LICENCE } from 'src/app/global.tokens';
import { christmasCalendarTrx, loginTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { GlobalNotificationsComponent } from '../../notifications/global-notifications/global-notifications.component';
import { isPlatformServer } from '@angular/common';
import { AdformTrackingPage, AdformTrackingService } from 'src/app/services/tracking/adform-tracking.service';

@Component({
  selector: 'app-desktop-front-page',
  templateUrl: './desktop-front-page.component.html',
  styleUrls: ['./desktop-front-page.component.scss']
})
export class DesktopFrontPageComponent extends BasePageComponent implements AfterViewInit {
  isCalendar: boolean;
  isVipPage: boolean = false;
  @ViewChild('globalNotifications', { static: false, read: ViewContainerRef })
  public viewRef: ViewContainerRef;

  constructor(
    private router: Router,
    public detectDeviceService: DetectDeviceService,
    public minimizeSiteService: MinimizeSiteService,
    protected licenceService: LicenceService,
    private adformService: AdformTrackingService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
    @Inject(PLATFORM_ID) private platformId,
    private _injector: Injector,
  ) {
    super()
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.checkCalendar(event.url);
        this.isVipPage = event?.url?.includes('vip');
      }
    });
  }

  categories: GameCategory[];
  operators: GameCategory[];
  showFiller = false;
  @ViewChild('drawer') drawer: MatDrawer;
  toggleClosed: boolean = true;
  isOpened: boolean = false;


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!!this.drawer) {
      this.drawer.openedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = true
      )
      this.drawer.closedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = false
      )
    }
    if (!isPlatformServer(this.platformId)) {
      this.adformService.setTrackingPage(AdformTrackingPage.HOMEPAGE);

      import('../../../modules/global-notifications/global-notifications.module').then((importedFile) => {
        const componentToOpen =
          importedFile.GlobalNotificationsModule.components.dynamicComponent;
        this._openLazyLoadedGlobalNotificationsComponent(importedFile, componentToOpen);
      });
    }
  }

  private _openLazyLoadedGlobalNotificationsComponent<T>(
    importedFile: T,
    componentToOpen: Type<GlobalNotificationsComponent>
  ): void {
    const module: Type<T> = (<any>importedFile)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModuleRef(module, this._injector);
    this.viewRef.createComponent(componentToOpen, { ngModuleRef: moduleRef });
  }

  login() {
    this.router.navigate([this.router.url, loginTrx]);
  }

  closeDrawer() {
    this.drawer.close();
  }
  toggleDrawer(closed: boolean) {
    this.toggleClosed = closed;
    this.drawer.toggle();
  }

  checkCalendar(route: string) { // To make christmas page into full width instead of fixed width
    var check = '/' + christmasCalendarTrx;
    if (check == route || '/' + christmasCalendarTrx == route) {
      this.isCalendar = true;
    } else {
      this.isCalendar = false;
    }
  }

}
