<div class="top-menu-wrapper lato">
    <div *ngIf="!isLogin else logged" class="desktop-top-menu" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding-left: 32px;min-width: 200px;">
            <ng-container *ngIf="isBannerExist">
                <button  appToggBtn (click)="toggleBanner()"></button>
                <p i18n style="padding-left: 8px;">Hide Promotions</p>
            </ng-container>
            <ng-container *ngIf="!isBannerExist">
                <span style="width: 132px;"></span>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-evenly center">
            <a [routerLink]="slots" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><img src="{{licenceService.getKey('icon-top-slot')}}" alt=""><span i18n>Slots</span></a>
            <!-- <a [routerLink]="arcadeUrl" routerLinkActive="active" i18n>Arcade</a> -->
            <a [routerLink]="aviatorUrl" routerLinkActive="active" ><img src="{{licenceService.getKey('icon-top-aviator')}}" alt=""><span i18n>Aviator</span></a>
            <a [routerLink]="liveCasinoUrl" routerLinkActive="active" ><img src="{{licenceService.getKey('icon-top-live-casino')}}" alt=""><span i18n>Live Casino</span></a>
            <!-- <a [routerLink]="promotionsTrx" routerLinkActive="active" i18n>Promotions</a> -->
            <!-- <a [topDrawerRouterLink]="[lostPasswordTrx]" i18n>lost password</a> -->
        </div>
        <span style="width: 200px;"></span>
    </div>
</div>
<ng-template #logged>
    <div class="desktop-top-menu lato" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding-left: 32px; min-width: 200px;">
            <ng-container *ngIf="isBannerExist">
                <button  appToggBtn (click)="toggleBanner()"></button>
                <p i18n style="padding-left: 8px;">Hide Promotions</p>
            </ng-container>
            <ng-container *ngIf="!isBannerExist">
                <span style="width: 132px;"></span>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-evenly center">
            <a [routerLink]="['',slots]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><img src="assets/playbison/icons/icon-slot.png" alt=""><span i18n>Slots</span></a>
            <!-- <a *ngIf="!isInGame" [routerLink]="['']"><img class="inline-logo" alt="inline-logo" src="assets/pages/promobb/logo-casino-white.svg" /></a> -->
            <!-- <a [routerLink]="arcadeUrl" routerLinkActive="active" i18n>Arcade</a> -->
            <!-- <a [routerLink]="aviatorUrl" routerLinkActive="active" i18n>Aviator</a> -->
            <a [routerLink]="aviatorUrl" routerLinkActive="active" ><img src="{{licenceService.getKey('icon-top-aviator')}}" alt=""><span i18n>Aviator</span></a>
            <a [routerLink]="liveCasinoUrl" routerLinkActive="active" ><img src="{{licenceService.getKey('icon-top-live-casino')}}" alt=""><span i18n>Live Casino</span></a>
            <!-- <a [routerLink]="prifileTrx" i18n>profile</a> -->
        </div>
        <span style="width: 200px;"></span>
    </div>
</ng-template>