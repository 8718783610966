<mat-drawer-container class="example-container" autosize (backdropClick)="toggleDrawer(true)">
    <mat-drawer #drawer class="desktop-sidenav" mode="over">
        <app-desktop-sidenav (closeNav)="closeDrawer()" [isVisible]="isOpened" [toggle]="toggleClosed"></app-desktop-sidenav>
    </mat-drawer>

    <div class="sidenav-content">
        <div class="desktop-fixed-wrapper">

            <div class="desktop-top" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="nav-aside">
                    <a (click)="toggleDrawer(false)">
                        <img src="{{licenceService.getKey('icon-menu-btn')}}" alt="nav-aside">
                    </a>
                </div>
                <div class="top-logo">
                    <a [routerLink]="['/']"><img [ngClass]="licence" src="{{licenceService.getKey('logo-desktop')}}" alt="lemon-logo" /></a>
                </div>
                <div class="profile-wrapper">
                    <app-user-profile></app-user-profile>
                </div>
                <div class="auth-wrapper">
                    <app-main-auth></app-main-auth>
                </div>
            </div>
        </div>
        <app-desktop-top-menu></app-desktop-top-menu>
        <app-breadcrumbs *ngIf="!isVipPage"></app-breadcrumbs>
        <div [ngClass]="[isCalendar?'':'content-wrapper', isVipPage ? 'vip-page' : '']">
            <router-outlet></router-outlet>
        </div>
        <app-desktop-footer *ngIf="minimizeSiteService.isFooterNotMinimized()|async"></app-desktop-footer>
    </div>
    <ng-container #globalNotifications></ng-container>
    <!-- <app-global-notifications></app-global-notifications> -->

</mat-drawer-container>



<!-- <app-footer *ngIf="minimizeSiteService.isRegularPage()|async"></app-footer> -->