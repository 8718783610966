<div class="logout-button" [ngClass.gt-sm]="'desktop'">
    <!-- <button *ngIf="!detectDeviceService.isDesktop()" mat-flat-button color="basic" (click)="logout()" i18n>log out</button> -->
    <div class="leave-btn" round-button color="warn" fxLayoutAlign="center center" fxLayout="row" filled="filled" size="small" color="warn" (click)="logout()">
        <img src="assets/playbison/icon-logout.png">
        <span i18n>log out</span>
        <!-- <mat-icon>close</mat-icon> -->
    </div>
    <!-- <ng-container *ngIf="detectDeviceService.isDesktop()">
        <mat-list-item class="desktop-menu-item">
            <a class="desktop-menu-logout" (click)="logout()" i18n>log out</a>
        </mat-list-item>
    </ng-container> -->
</div>