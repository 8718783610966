import { MobileOperatorsModule } from './modules/mobile-operators/mobile-operators.module';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { Inject, Injector, makeStateKey, NgModule, PLATFORM_ID, TransferState } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrontPageComponent } from './components/front-page/front-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StaticPageComponent } from './components/static-page/static-page.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { SharedMaterialModule } from './modules/shared-material/shared-material.module';
import { BaseComponent } from './components/base/base.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { GameDialogComponent } from './components/dialog/game-dialog/game-dialog.component';
import {
  ResumeGameDialogComponent,
  ResumeGameDialogRouteComponent
} from './components/dialog/resume-game-dialog/resume-game-dialog.component';
import { isPlatformServer, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeHu from '@angular/common/locales/hu';
import localedeAT from '@angular/common/locales/de-AT';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/auth/login/login.component';
import {
  LoginDialogComponent,
  LoginDialogRouteComponent,
  LoginStandaloneComponent
} from './components/auth/login-dialog/login-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LostPasswordComponent } from './components/auth/lost-password/lost-password.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppShellNoRenderDirective } from './directives/ssr-no-render.directive';
import { CheckSessionService } from './services/auth/check-session/check-session.service';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { BalanceStatusService } from './services/wallet/balance-status.service';
import { WrongPasswordComponent } from './components/auth/login/wrong-password/wrong-password.component';
import { WrongPasswordDialogComponent } from './components/auth/login/wrong-password/wrong-password-dialog/wrong-password-dialog.component';
import { BottomToolbarComponent } from './components/toolbar/bottom-toolbar/bottom-toolbar.component';
import { ErrorDialogComponent } from './components/dialog/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from './components/dialog/success-dialog/success-dialog.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth.guard';
import { DrawerGuard } from './drawer.guard';
import { UserInfoService } from './services/auth/check-session/user-info.service';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from './components/utils/loader/loader.component';
import { TopMenuButtonComponent } from './components/top-menu/top-menu-button/top-menu-button.component';
import { GameCategoryDialogComponent } from './components/front-page/game-category-dialog/game-category-dialog.component';
import { TopMenuItemComponent } from './components/top-menu/top-menu-item/top-menu-item.component';
import { FirebaseAuthService } from './services/auth/firebase/firebase-auth.service';
import { SelectAvatarComponent } from './components/profile/edit-profile/select-avatar/select-avatar.component';
import { TopMenuBalanceComponent } from './components/top-menu/top-menu-balance/top-menu-balance.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationSheetComponent } from './components/notifications/notification-sheet/notification-sheet.component';
import { LastPlayedComponent } from './components/front-page/last-played/last-played.component';
import { LastPlayedFullComponent } from './components/games/last-played-full/last-played-full.component';
import { SharedServicesModule } from './modules/shared-services/shared-services.module';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { StepperComponent } from './components/auth/signup/stepper/stepper.component';
import { TermsDialogComponent } from './components/payment/deposit/deposit-bonus-selector/terms-dialog/terms-dialog.component';
import { DesktopCategoryMenuComponent } from './components/front-page/desktop-category-menu/desktop-category-menu.component';
import { DesktopTopProfileInfoComponent } from './components/profile/desktop-top-profile-info/desktop-top-profile-info.component';
import { BaseGamePage } from './components/games/ase-game-page.component';
import { TranslateUrlPipe } from './pipes/translate-url.pipe';
import { CategoryScrollComponent } from './components/front-page/category-scroll/category-scroll.component';
import { MaximumPageResolver } from './resolvers/restore-max-page.resolver';
import { MinimizePageResolver } from './resolvers/minimize-page.resolver';
import { NewPasswordComponent } from './components/auth/new-password/new-password.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { TeaserTopMobileComponent } from './components/teaser-top-mobile/teaser-top-mobile.component';
import { ProfileButtonComponent } from './components/toolbar/profile-button/profile-button.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { setContext } from '@apollo/client/link/context';
import { Apollo, ApolloModule } from 'apollo-angular';
import { AngularFireModule } from '@angular/fire/compat';
import { formatLocaleCMS } from './utils/format-locale-cms';
import {
  LostPasswordDialogComponent,
  LostPasswordDialogRouteComponent
} from './components/auth/lost-password-dialog/lost-password-dialog.component';
import { DesktopFrontPageComponent } from './components/desktop/desktop-front-page/desktop-front-page.component';
import { DesktopSidenavComponent } from './components/desktop/desktop-sidenav/desktop-sidenav.component';
import { DesktopFooterComponent } from './components/desktop/footer/footer.component';
import { DesktopTopMenuComponent } from './components/desktop/desktop-top-menu/desktop-top-menu.component';
import { DESKTOP_MODULE_ROUTES } from './components/desktop/desktop.routing.module';
import { MobileNotFoundComponent } from './components/mobile-not-found/mobile-not-found.component';
import { BreadcrumbsComponent } from './components/desktop/breadcrumbs/breadcrumbs.component';
import { NavigationService } from './services/navigation/navigation.service';
import { RecommendationsComponent } from './components/front-page/recommendations/recommendations.component';
import { RecommendationsFullComponent } from './components/games/recommendations-full/recommendations-full.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MissionsCarouselDynamicModule } from './components/missions/missions-carousel.dynamic.module';
import { GamificationProgressComponent } from './components/front-page/gamification-progress/gamification-progress.component';
import { ClientAreaModule } from "./components/client-area/client-area.module";
import { MyGamesListComponent } from './components/front-page/my-games-list/my-games-list.component';
import { PaymentConfirmationComponent } from './utils/payment-confirmation/payment-confirmation.component';
import { TrimDirective } from './directives/trim.directive';
import { NotificationsEmailComponent } from './components/front-page/notifications-email/notifications-email.component';
import { DownloadInfobarComponent } from './components/utils/pwa/download-infobar/download-infobar.component';
import { InstallPopupComponent } from './components/utils/pwa/install-popup/install-popup.component';
import { SearchGameSwiperComponent } from './components/games/search-game/search-game-swiper/search-game-swiper.component';
import { GameCategorySwiperComponent } from './components/front-page/game-category-swiper/game-category-swiper.component';
import { AppSwiperModule } from './components/games/search-game/swiper-module/swiper.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { DESKTOP_ROUTES, HOST_NAME } from './global.tokens';
import { DlagLogoComponent } from './components/dlag-logo/dlag-logo.component';
import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import { CACHED_CMS_QUERY } from "./services/http/base-http.service";
import { BonusExcludedGameComponent } from './components/desktop/game-launcher/bonus_excluded-dialog/bonus_excluded-dialog.component';

registerLocaleData(localePl, 'pl');
registerLocaleData(localeHu, 'hu');
registerLocaleData(localedeAT, 'de-AT');

const STATE_KEY = makeStateKey<any>('apollo.state');
const TEST_STATE_KEY = makeStateKey<any>('test.state');

@NgModule({
  declarations: [
    AppComponent,
    FrontPageComponent,
    StaticPageComponent,
    BasePageComponent,
    BaseComponent,
    NotFoundComponent,
    AppShellNoRenderDirective,
    GameDialogComponent,
    ResumeGameDialogComponent,
    TopMenuComponent,
    FooterComponent,
    LoginComponent,
    LoginDialogComponent,
    LoginStandaloneComponent,
    LostPasswordComponent,
    LogoutComponent,
    WrongPasswordComponent,
    WrongPasswordDialogComponent,
    BottomToolbarComponent,
    ErrorDialogComponent,
    SuccessDialogComponent,
    ConfirmDialogComponent,
    LoaderComponent,
    TopMenuButtonComponent,
    GameCategoryDialogComponent,
    TopMenuItemComponent,
    SelectAvatarComponent,
    TopMenuBalanceComponent,
    NotificationsComponent,
    NotificationSheetComponent,
    LastPlayedComponent,
    LastPlayedFullComponent,
    RecommendationsComponent,
    RecommendationsFullComponent,
    MyGamesListComponent,
    StepperComponent,
    TermsDialogComponent,
    PaymentConfirmationComponent,
    DesktopCategoryMenuComponent,
    DesktopTopProfileInfoComponent,
    ResumeGameDialogRouteComponent,
    BaseGamePage,
    LoginDialogRouteComponent,
    TranslateUrlPipe,
    CategoryScrollComponent,
    NewPasswordComponent,
    TeaserTopMobileComponent,
    ProfileButtonComponent,
    LostPasswordDialogRouteComponent,
    LostPasswordDialogComponent,
    BreadcrumbsComponent,
    DesktopFrontPageComponent,
    DesktopSidenavComponent,
    DesktopTopMenuComponent,
    DesktopFooterComponent,
    MobileNotFoundComponent,
    GameCategorySwiperComponent,
    GamificationProgressComponent,
    PaymentConfirmationComponent,
    DownloadInfobarComponent,
    InstallPopupComponent,
    GamificationProgressComponent,
    NotificationsEmailComponent,
    DlagLogoComponent,
    BonusExcludedGameComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    SharedServicesModule.forRoot(),
    TransferHttpCacheModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    SharedMaterialModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    ApolloModule,
    SharedComponentsModule,
    InfiniteScrollModule,
    MobileOperatorsModule,
    MissionsCarouselDynamicModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorker || false }),
    ClientAreaModule,
    AppSwiperModule,
  ],
  providers: [
    CookieService,
    FirebaseAuthService,
    CheckSessionService,
    BalanceStatusService,
    UserInfoService,
    DrawerGuard,
    Title,
    MinimizePageResolver,
    MaximumPageResolver,
    AuthGuard,
    NavigationService,
    {
      provide: FIRESTORE_SETTINGS,
      useValue: { ignoreUndefinedProperties: true }
    },
    {
      provide: DESKTOP_ROUTES,
      useValue: DESKTOP_MODULE_ROUTES
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  static mInjector: Injector;
  cache: InMemoryCache;

  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    protected transferState: TransferState,
    injector: Injector,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(HOST_NAME) public hostName
  ) {
    AppModule.mInjector = injector;
    this.cache = new InMemoryCache();
    const isBrowser = isPlatformServer(this.platformId) === false;

    const basic = setContext((operation, context) => ({
      headers: !!environment.cmsDraftToken ? {
        Authorization: `Bearer ${environment.cmsDraftToken}`,

      } : {
        // "Accept": `application/json, text/plain, */*, ${new Date().getTime().toString()}`
      }
    }));


    const localeMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      // we assume `headers` as a defined instance of HttpHeaders
      let v = operation.variables;
      if (operation.variables['locale']) {
        operation.variables['locale'] = formatLocaleCMS(operation.variables['locale']);
      }
      if (operation.variables['countryName']) {
        operation.variables['countryName'] = [formatLocaleCMS(operation.variables['locale'])];
      }
      operation.variables['buildversion'] = '3299900023';
      return forward(operation);
    });

    //It souldnt be here only to play and debug
    this.transferState.set(TEST_STATE_KEY, <string>'hello');

    const cachedCms = environment.production ? environment.cmsCacheUrl + this.hostName.replace('cache.', '') + CACHED_CMS_QUERY : environment.cmsApi;

    apollo.create({
      link: ApolloLink.from([basic, localeMiddleware, httpLink.create({ uri: cachedCms })]),
      cache: this.cache,

      ...(isBrowser
        ? {
          // queries with `forceFetch` enabled will be delayed
          ssrForceFetchDelay: 200,
        }
        : {
          // avoid to run twice queries with `forceFetch` enabled
          ssrMode: true,
        }),
    });
    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();

    }


  }

  onServer() {
    // console.log("serializing", this.transferState, STATE_KEY)
    this.transferState.onSerialize(STATE_KEY, () => {
      return this.cache.extract();
    });
  }

  onBrowser() {
    const state = this.transferState.get<any>(STATE_KEY, null);
    this.cache.restore(state);

  }

}
