import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HidePromotionsService {

  private showBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private bannerExist: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  isShowBanner() {
    return this.showBanner.asObservable();
  }

  isBannerExist() {
    return this.bannerExist.asObservable();
  }

  toggleShowBanner() {
    this.showBanner.next(!this.showBanner.value);
  }

  resetShowBanner() {
    this.showBanner.next(true);
  }
  setBannerExist(state: boolean) {
    this.bannerExist.next(state);
  }

}
